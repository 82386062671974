import React from "react"
import {Link} from "gatsby"
import Layout from "../layouts/default"
import SEO from "../components/SEO"
import QuoteForm from "../components/QuoteForm"

const QuotePage = () => (
  <Layout noHeaderImage={true}>
    <SEO title="Quote Form" />
    <div className="container mt-5 pt-5 text-center">
        <h1 className="page-title my-5 display-4 text-icl">Request a Quote</h1>
        <QuoteForm productsToShow={"all"} />
    </div>
  </Layout>
)

export default QuotePage